<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
      :headers="headers"
      :items="broadcastMessages"
      :options.sync="pagination"
      :server-items-length="totalBroadcastMessages"
      :loading="loading"
      :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >

      <template v-slot:item.cities="{ item }">
        <v-chip
            class="ma-2"
            color="blue darken-1"
            text-color="white"
            v-if="item.allCities === true"
        >
          ALL CITIES
        </v-chip>

        {{ item.allCities === false && item.cities != null && item.cities.length > 0 ? item.cities[0].name : "" }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.allCities === false && item.cities != null && item.cities.length > 1" small class="mr-2" v-on="on"
            >mdi-dots-horizontal</v-icon
            >
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.cities" :key="i">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item.schedule="{ item }">
        {{ parseDate(item.schedule) }}
      </template>
      <template v-slot:item.sent="{ item }">
        <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            v-if="item.sent"
        >
          Sent successfully
        </v-chip>
        <v-chip
            class="ma-2"
            color="red"
            text-color="white"
            v-if="!item.sent"
        >
          Not sent
        </v-chip>
      </template>

      <template v-slot:item.forShopUsers="{ item }">
        <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            v-if="item.forShopUsers"
        >
          Enabled
        </v-chip>
        <v-chip
            class="ma-2"
            color="red"
            text-color="white"
            v-if="!item.forShopUsers"
        >
          Disabled
        </v-chip>
      </template>
      <template v-slot:item.clear="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <router-link
            :to="{
              name: 'BroadcastMessageEdit',
              params: { broadcastMessageId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar color="red darken-4" size="26" rounded>
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>

          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveBroadcastMessages();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <router-link :to="{ name: 'BroadcastMessageCreate' }">
              <v-avatar color="teal" size="26" rounded>
                <v-icon dark>mdi-plus</v-icon>
              </v-avatar>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["currentUser"]),
    headers() {
      return  [
        { text : "Id" , align : "start" , sortable : true , value : "id" } ,
        { text : this.$i18n.t('pages.broadcastMessages.subject') , value : "subject" } ,
        { text : this.$i18n.t('pages.broadcastMessages.body') , value : "body" } ,
        { text : this.$i18n.t('pages.broadcastMessages.schedule') , value : "schedule" } ,
        { text : this.$i18n.t('commonTable.cities') , value : "cities" } ,
        { text : this.$i18n.t('pages.broadcastMessages.forShopUsers') , value : "forShopUsers" } ,
        { text : this.$i18n.t('pages.broadcastMessages.sent') , value : "sent" } ,
        { text : "" , value : "search" , width : "5%" } ,
        { text : "" , value : "clear" , width : "5%" } ,
        { text : "" , value : "add" , width : "5%" }
      ]
    }
  },

  name: "broadcast-messages",
  data() {
    return {
      totalBroadcastMessages: 0,
      broadcastMessages: [],
      loading: true,
      pagination: {},
      dialogDelete: false,
      itemToDelete: null,
      errors: []
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveBroadcastMessages();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Broadcast Messages" }]);
  },
  components: {
    ConfirmDialog
  },
  methods: {
    parseDate(date) {
      return moment
        .utc(date)
        .local()
        .format("yyyy-MM-DD HH:mm:ss");
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t('common.confirm'),
          this.$i18n.t('pages.broadcastMessages.deleteMessage') +
            item.id +
            " ?"
        )
      ) {
        this.deleteRecord(item);
      }
    },
    deleteRecord(item) {
      this.deleteBroadcastMessage(item.id);
    },
    deleteBroadcastMessage(id) {
      this.loading = true;
      ApiService.delete(`api/broadcast-message/${id}`)
        .then(response => {
          this.$log.debug("Broadcast message deleted: ", response);
          this.retrieveBroadcastMessages();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getRequestParams() {
      let params = {};

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    retrieveBroadcastMessages() {
      const params = this.getRequestParams();
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/broadcast-message", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Broadcast messages: ", response.data.content);
          this.broadcastMessages = response.data.content;
          this.totalBroadcastMessages = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title =
            this.totalBroadcastMessages != null
              ? this.totalBroadcastMessages
              : 0;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Broadcast Messages: " + title }
          ]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}
.v-icon.v-icon {
  font-size: 24px !important;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
